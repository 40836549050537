<template>
	<div style="height: 100%">
		<div style="height: 88%; background-color: #fff; border-radius: 8px">
			<!-- 标签页 -->
			<el-tabs
				v-model="activeName"
				type="card"
				@tab-click="handleClick"
				style="padding-top: 15px; padding-left: 15px"
			>
				<el-tab-pane name="4" v-if="authorization('/home-admin/device/device-borrow/all')">
					<span slot="label">全部转借记录 </span>
				</el-tab-pane>
				<el-tab-pane name="1" v-if="authorization('/home-admin/device/device-borrow/myLend')">
					<span slot="label">我的借出 </span>
				</el-tab-pane>
				<el-tab-pane name="2" v-if="authorization('/home-admin/device/device-borrow/myBorrow')">
					<span slot="label">我的借入 </span>
				</el-tab-pane>
				<el-tab-pane name="3" v-if="authorization('/home-admin/device/device-borrow/cancel')">
					<span slot="label">转借取消 </span>
				</el-tab-pane>
			</el-tabs>
			<!-- 搜索栏 -->
			<!-- 表格 -->
			<div style="height: 84%; background-color: #fff; border-radius: 8px">
				<el-form
					:inline="true"
					v-show="activeName == 4 || activeName == 3"
					style="
						padding-left: 20px;
						margin-bottom: 10px;
						background-color: #fff;
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					"
					size="mini"
				>
					<el-form-item label="师傅">
						<el-select filterable v-model="params.userId" placeholder="请选择">
							<el-option
								v-for="item in userList"
								:key="item.id"
								:label="item.userName"
								:value="item.id"
							></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态">
						<el-select filterable v-model="params.status" placeholder="请选择">
							<el-option label="待确认" :value="1"></el-option>
							<el-option label="已确认" :value="2"></el-option>
							<el-option label="已取消" :value="3"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleClick" size="mini">查询</el-button>
						<el-button type="info" @click="reset" size="mini">重置</el-button>
					</el-form-item>
				</el-form>
				<el-table
					:data="tableData"
					ref="multipleTable"
					stripe
					height="100%"
					style="margin-left: 20px; width: 98%"
					size="small"
					:header-cell-style="{
						'background-color': '#84aace4a',
						color: '#343333',
						'text-align': 'center',
					}"
					:cell-style="{ 'text-align': 'center' }"
				>
					<el-table-column
						prop="categoryName"
						label="转借设备类型名"
						:show-overflow-tooltip="true"
					></el-table-column>
					<el-table-column prop="count" label="数量"></el-table-column>
					<el-table-column prop="inputName" label="借入人"></el-table-column>
					<el-table-column prop="outName" label="借出人"></el-table-column>
					<el-table-column prop="status" label="状态" :formatter="statusFormat"></el-table-column>
					<el-table-column
						prop="createTime"
						label="借出时间"
						:formatter="(row) => timeFormat(row.createTime)"
					></el-table-column>
					<el-table-column prop="operateName" label="操作人"></el-table-column>
					<el-table-column
						prop="operateTime"
						label="操作时间"
						:formatter="(row) => timeFormat(row.operateTime)"
					></el-table-column>
					<el-table-column label="操作" fixed="right">
						<template slot-scope="scope">
							<el-tooltip effect="dark" content="详情" placement="top-end">
								<el-button
									type="info"
									icon="el-icon-tickets"
									circle
									size="mini"
									@click="details(scope.row)"
									style="margin-left: 10px"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="确认" placement="top-end">
								<el-button
									content="确认"
									type="success"
									icon="el-icon-check"
									style="margin-left: 10px"
									circle
									size="mini"
									v-show="
										(activeName == 2 ||
											(activeName == 4 &&
												authorization('/home-admin/device/device-borrow/confirm'))) &&
										scope.row.status == 1
									"
									@click="confirm(scope.row)"
								></el-button>
							</el-tooltip>
							<el-tooltip effect="dark" content="取消" placement="top-end">
								<el-button
									content="取消"
									type="warning"
									icon="el-icon-close"
									style="margin-left: 10px"
									circle
									size="mini"
									v-show="
										(activeName == 1 ||
											(activeName == 4 &&
												authorization('/home-admin/device/device-borrow/cancle'))) &&
										scope.row.status == 1
									"
									@click="cancel(scope.row)"
								></el-button>
							</el-tooltip>
						</template>
					</el-table-column>
				</el-table>
				<div
					style="
						padding-top: 20px;
						height: 40px;
						background-color: #fff;
						width: 100%;
						border-radius: 8px;
						text-align: right;
					"
				>
					<el-pagination
						background
						:page-sizes="sizes"
						layout="total,sizes,prev,pager,next"
						:total="total"
						@current-change="handleCurrentChange"
						@size-change="sizeChange"
					></el-pagination>
				</div>
			</div>
		</div>
		<el-dialog
			title="转借详情"
			:visible.sync="detailDialog"
			width="800px"
			:close-on-click-modal="false"
		>
			<el-table :data="detailData" stripe style="padding: 0 20px" height="80%" size="small">
				<el-table-column prop="deviceNum" label="设备编号"></el-table-column>
				<el-table-column prop="categoryName" label="所属类型"></el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import moment from 'moment';
	import { authorization } from '../../../authorization/authorization';
	export default {
		data() {
			return {
				userList: [],
				detailDialog: false,
				defaultShortcut:
					sessionStorage.getItem('defaultShortcut') === undefined
						? 0
						: Number(sessionStorage.getItem('defaultShortcut')),
				userId: Number(sessionStorage.getItem('userId')),
				tableData: [],
				detailData: [],
				multipleSelection: [],
				activeName: '1',
				params: {
					type: '',
					userId: '',
					status: '',
					page: 1,
					size: 10,
				},
				total: 0,
				sizes: [10, 15, -1],
				orderCount: {},
			};
		},
		created() {
			this.params.type = this.activeName;
			this.queryDeviceBorrowrList();
			this.queryUserList();
		},
		methods: {
			authorization,
			queryUserList() {
				//所属师傅下拉（包含暂不接单的师傅）
				this.axios({
					method: 'get',
					url: '/v1/web/user-info/select-master-drown',
				}).then((res) => {
					this.userList = [];
					let obj = {};
					obj.id = 0;
					obj.userName = '全部';
					this.userList.push(obj);
					for (let i = 0; i < res.data.data.list.length; i++) {
						let userInfo = res.data.data.list[i].userName;
						userInfo = userInfo.substring(0, userInfo.indexOf('('));
						res.data.data.list[i].userName = userInfo;
						this.userList.push(res.data.data.list[i]);
					}
				});
			},
			details(row) {
				this.axios({
					method: 'get',
					url: '/v1/web/device-manage/get-device-ids',
					params: {
						deviceIds: row.deviceId,
					},
				}).then((res) => {
					this.detailData = res.data.data;
					this.detailDialog = true;
				});
			},
			getOrderCount() {
				this.axios({
					method: 'get',
					url: '/v1/h5/order-info/select-order-count',
				}).then((res) => {
					this.orderCount = res.data.data;
				});
			},
			textInput() {
				this.textContent = this.textContent.replace(/\r|\n|\s/gi, ' ');
			},
			handleClick() {
				this.params.type = this.activeName;
				this.queryDeviceBorrowrList();
			},
			queryDeviceBorrowrList() {
				//获取订单列表
				this.axios({
					method: 'get',
					url: '/v1/web/device-manage/get-history',
					params: this.params,
				}).then((res) => {
					this.tableData = res.data.data.list;
					this.total = res.data.data.total;
				});
			},
			cancel(row) {
				//取消
				this.axios({
					method: 'post',
					url: '/v1/web/device-manage/operation-cancel/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ type: 'success', message: '取消成功' });
						this.getOrderCount();
						this.queryDeviceBorrowrList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			confirm(row) {
				//确认
				this.axios({
					method: 'post',
					url: '/v1/web/device-manage/operation-confirm/' + row.id,
					params: {
						id: row.id,
					},
				}).then((res) => {
					if (res.data.code === 200) {
						this.$message({ type: 'success', message: '确认成功' });
						this.getOrderCount();
						this.queryDeviceBorrowrList();
					} else {
						this.$message({ type: 'error', message: res.data.message });
					}
				});
			},
			timeFormat: function (time) {
				if (time !== null && time !== undefined) {
					return moment(time).format('YYYY-MM-DD HH:mm:ss');
				}
			},
			statusFormat: function (row) {
				if (row.status === 1) {
					return '待确认';
				} else if (row.status === 2) {
					return '已确认';
				} else if (row.status === 3) {
					return '已取消';
				}
			},
			handleCurrentChange(val) {
				this.params.page = val;
				this.queryDeviceBorrowrList();
			},
			sizeChange(val) {
				this.params.size = val;
				this.params.page = 1;
				this.queryDeviceBorrowrList();
			},
			reset() {
				this.params = { page: 1, size: 15 };
				this.params.type = this.activeName;
				this.params.userId = '';
				this.queryDeviceBorrowrList();
			},
		},
	};
</script>

<style scoped></style>
